/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/popper.js@1.16.1/dist/umd/popper.min.js
 * - /npm/bootstrap@4.6.0/dist/js/bootstrap.min.js
 * - /npm/blazy@1.8.2/blazy.min.js
 * - /npm/waypoints@4.0.1/lib/noframework.waypoints.min.js
 * - /gh/osvaldasvalutis/disqusLoader.js@master/disqusloader.min.js
 * - /npm/@splidejs/splide@3.6.12/dist/js/splide.min.js
 * - /npm/lite-youtube-embed@0.2.0/src/lite-yt-embed.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
